import * as React from "react";
import clsx from "clsx";

import SectionHeading from "../SectionHeading/SectionHeading";
import SearchLocations from "../SearchLocations/SearchLocations";

import "./CityTitleBanner.css";

import BannerImage from "../../images/HOME.webp";

export default function CityTitleBanner({ city, searchCityArticles }) {
  return (
    <div
      className={clsx("city-title-banner", {
        search: searchCityArticles,
      })}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div className="city-title-banner-content">
        <div className="city-title-section">
          {city && (
            <div>
              <div>DESTINATIONS</div>
              <h1 className="city-title-heading">{city.name.toUpperCase()}</h1>
            </div>
          )}
          {searchCityArticles && (
            <div style={{ width: "100%" }}>
              <SectionHeading className="city-title-banner-title">
                WHERE ARE YOU GOING?
              </SectionHeading>
              <div className="city-title-banner-title">
                From skip the line entry tickets and city passes to guided tours
                and day trips, find your experience here
              </div>
              <SearchLocations />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
