import * as React from "react";
import { useLocation } from "@reach/router";
import clsx from "clsx";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Button from "../components/Button/Button";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import CityTitleBanner from "../components/CityTitleBanner/CityTitleBanner";
import CityLink from "../components/CityLink/CityLink";
import LatestArticle from "../components/LatestArticle/LatestArticle";
import SearchResultCarousel from "../components/SearchResultCarousel/SearchResultCarousel";
import SearchResult from "../components/SearchResult/SearchResult";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";

import continentCities from "./cities.json";

import "./HomePage.css";
import { getPopularActivities as apiGetPopularActivities } from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

export const query = graphql`
  query {
    allContentfulArticle {
      edges {
        node {
          id
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          slug
          thumbnailImageUrl
          hideFromHomePage
        }
      }
    }
    allPopularActivities {
      edges {
        node {
          id
          activityUrl
          thumbnailUrl
          city
          title
          description
          averageRating
          totalReviews
          duration
          durationTo
          durationFrom
          source
          price
        }
      }
    }
  }
`;

const stats = [
  {
    value: "500,000+",
    label: "Tours & Activities",
  },
  {
    value: "4,000+",
    label: "Destinations",
  },
  {
    value: "5,000+",
    label: "Travel Partners",
  },
  {
    value: "100+ countries",
    label: "Helping travelers explore",
  },
];

const cityLinks = [
  {
    city: "New York",
    slug: "/united-states-of-america/new-york/new-york-city",
    image: (
      <StaticImage
        src={"../images/cities/new-york.jpg"}
        alt="Barcelona"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "London",
    slug: "/united-kingdom/london",
    image: (
      <StaticImage
        src={"../images/cities/london.jpg"}
        alt="London"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Dubai",
    slug: "/united-arab-emirates/dubai",
    image: (
      <StaticImage
        src={"../images/cities/dubai.jpg"}
        alt="Dubai"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Paris",
    slug: "/france/paris",
    image: (
      <StaticImage
        src={"../images/cities/paris.jpg"}
        alt="Paris"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Barcelona",
    slug: "/spain/barcelona",
    image: (
      <StaticImage
        src={"../images/cities/barcelona.jpg"}
        alt="Barcelona"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Las Vegas",
    slug: "/united-states-of-america/nevada/las-vegas",
    image: (
      <StaticImage
        src={"../images/cities/las-vegas.jpg"}
        alt="San Francisco"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Chicago",
    slug: "/united-states-of-america/illinois/chicago",
    image: (
      <StaticImage
        src={"../images/cities/chicago.jpg"}
        alt="Chicago"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Hawaii",
    slug: "/united-states-of-america/hawaii/big-island-of-hawaii",
    image: (
      <StaticImage
        src={"../images/cities/hawaii.jpg"}
        alt="Hawaii"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Los Angeles",
    slug: "/united-states-of-america/california/los-angeles",
    image: (
      <StaticImage
        src={"../images/cities/los-angeles.jpg"}
        alt="San Francisco"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Rome",
    slug: "/italy/rome",
    image: (
      <StaticImage
        src={"../images/cities/rome.jpg"}
        alt="Rome"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "San Francisco",
    slug: "/united-states-of-america/california/san-francisco",
    image: (
      <StaticImage
        src={"../images/cities/san-francisco.jpg"}
        alt="San Francisco"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
  {
    city: "Washington DC",
    slug: "/united-states-of-america/washington-dc",
    image: (
      <StaticImage
        src={"../images/cities/washington-dc.jpg"}
        alt="Washington DC"
        className="city-image"
        placeholder="blurred"
      ></StaticImage>
    ),
  },
];

const HomePage = ({ data }) => {
  const articles = data.allContentfulArticle.edges;

  const context = React.useContext(GlobalStateContext);

  const [selectedContinent, setSelectedContinent] = React.useState(
    continentCities[0]
  );
  const [popularActivities, setPopularActivities] = React.useState(
    data.allPopularActivities.edges.map((activity) => activity.node)
  );
  const [currencyChanged, setCurrencyChanged] = React.useState(false);

  const [loadingPopularActivities, setLoadingPopularActivities] =
    React.useState(false);

  const getPopularActivities = async () => {
    // setErrors([]);

    try {
      setLoadingPopularActivities(true);

      const data = await apiGetPopularActivities(context.currentCurrency);

      if (data) {
        setPopularActivities(data);
      }
      setLoadingPopularActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingPopularActivities(false);
    }
  };

  React.useEffect(() => {
    if (context.currentCurrency.code !== "USD") {
      getPopularActivities();
    } else {
      setPopularActivities(
        data.allPopularActivities.edges.map((activity) => activity.node)
      );
    }
  }, [context.currentCurrency]);

  return (
    <Page>
      <TopBar />
      <Section>
        <CityTitleBanner searchCityArticles />
        <div className="who-we-are-stats">
          {stats.map((stat, index) => (
            <div key={`stat-${index}`} className="who-we-are-stat">
              <div className="who-we-are-stat-value">{stat.value}</div>
              <div className="who-we-are-stat-label">{stat.label}</div>
            </div>
          ))}
        </div>
        <div className="city-links">
          {cityLinks.map((cityLink, index) => (
            <CityLink
              key={cityLink.city}
              cityLink={cityLink}
              image={cityLink.image}
              small
            />
          ))}
        </div>
      </Section>
      <Section backgroundColor="#029ad15c">
        <SectionHeading component="h2">OUR TOP RECOMMENDATIONS</SectionHeading>
        <SearchResultCarousel
          slides={
            popularActivities &&
            popularActivities.map((activity) => (
              <SearchResult compact={true} searchResult={activity} />
            ))
          }
        />
      </Section>
      <Section>
        <div className="latest-articles-heading">
          <SectionHeading component="h2">LATEST ARTICLES</SectionHeading>
          <a href="/blog">
            <Button secondary>VIEW ALL</Button>
          </a>
        </div>
        <SearchResultCarousel
          slides={
            articles &&
            articles
              .filter((article) => !article.node.hideFromHomePage)
              .map((article) => <LatestArticle latestArticle={article.node} />)
          }
        />
      </Section>
      {/* <Section>
          <SectionHeading component="h2">THINGS TO DO IN...</SectionHeading>
          <div className="city-links">
            <div className="one-city-link">
              <CityLink
                large
                city="Barcelona"
                image={CityPlaceholder1}
                description="I am text block. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo."
              />
            </div>
            <div className="four-city-links">
              <CityLink small city="Barcelona" image={CityPlaceholder2} />
              <CityLink small city="Barcelona" image={CityPlaceholder3} />
              <CityLink small city="Barcelona" image={CityPlaceholder4} />
              <CityLink small city="Barcelona" image={CityPlaceholder5} />
            </div>
          </div>
        </Section> */}
      {/* <Section backgroundColor={"#fff"}>
        <div className="who-we-are-section">
          <div className="who-we-are-image-section" />
          <div className="who-we-are-text-section">
            <div className="who-we-are-content">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/mTvkjVF1LUs"
              ></iframe>
              <div className="who-we-are-text">
                <SectionHeading component="h2">WHO WE ARE</SectionHeading>
                <div className="who-we-are-text-description">
                  We are a travel marketplace and price comparison platform. We
                  provide travelers with access to over half a million tours,
                  activities, day trips, and transfers all over the world. We
                  help travelers from across the globe by allowing them to
                  research and book unforgettable travel experiences at the best
                  possible prices. We work closely with travel brands and tour
                  providers to connect our audience with exceptional travel
                  experiences, curated from the finest offerings and exclusive
                  deals available.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
      <Section backgroundColor="#FDF4EF">
        <SectionHeading component="h2">ALL DESTINATIONS</SectionHeading>
        <div>
          <div className="continent-tabs">
            {continentCities.map((continentCity, index) => (
              <div key={`continent-city-${index}`}>
                <div
                  onClick={() => setSelectedContinent(continentCity)}
                  className="continent-tab"
                >
                  {continentCity.continent}
                </div>
                <div className="continent-cities">
                  {continentCity.cities.map((city) => (
                    <a
                      key={`city-link-${city.name.toLowerCase()}`}
                      href={city.path}
                    >
                      <div className="continent-city">{city.name}</div>
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default HomePage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={
        "Travel-Lingual | From skip the line entry tickets and city passes to guided tours and day trips, find your experience here"
      }
      description={
        "Discover the world with Travel-Lingual. Explore endless possibilities and find the best deals on tours and attractions worldwide. Compare prices, plan your adventures, and make memories that last a lifetime. Start your journey today."
      }
      pathname={location.pathname}
    />
  );
};
