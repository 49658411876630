import * as React from "react";
import clsx from "clsx";

import "./SearchResult.css";

import StarIcon from "./star-icon.png";
import ClockIcon from "./clock-icon.png";

import { GlobalStateContext } from "../../context/GlobalContextProvider";

export default function SearchResults({ searchResult, compact, lazyLoad }) {
  const context = React.useContext(GlobalStateContext);

  const formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: context.currentCurrency ? context.currentCurrency.code : "USD",
  });

  const formatTimeDuration = (time) => {
    const [hours, minutes, seconds] = time.split(":");

    if (hours !== "00") {
      const hrs = parseInt(hours);
      const mins = parseInt(minutes);
      return `${hrs} hr${hrs > 1 ? "s" : ""} ${mins} min${mins > 1 ? "s" : ""}`;
    }

    if (minutes !== "00") {
      const mins = parseInt(minutes);
      return `${mins} min${mins > 1 ? "s" : ""}`;
    }

    if (seconds !== "00") {
      const secs = parseInt(seconds);
      return `${secs} sec${secs > 1 ? "s" : ""}`;
    }

    return "0 sec";
  };

  return (
    <a
      href={searchResult.activityUrl}
      target="_blank"
      className="search-result-link"
    >
      <div
        className={clsx("search-result", {
          ["compact"]: compact,
        })}
      >
        <div className="search-result-image-section">
          <img
            src={searchResult.thumbnailUrl}
            loading={lazyLoad ? "lazy" : "eager"}
          />
        </div>
        <div className="search-result-content-section">
          <div className="search-result-main">
            <div className="search-result-city">{searchResult.city}</div>
            <div className="search-result-title">
              {searchResult.title.toUpperCase()}
            </div>
            <div className="search-result-tagline">
              {searchResult.description}
            </div>
            <div
              className="search-result-book-now-button"
              style={{ width: "100%", textAlign: "right", marginTop: 10 }}
            >
              <div className="book-now">Book now</div>
            </div>
          </div>
          <div className="search-result-footer">
            <div>
              <div>
                <div className="search-result-details">
                  {searchResult.totalReviews != null &&
                    searchResult.totalReviews > 0 && (
                      <div className="search-result-rating">
                        <img
                          src={StarIcon}
                          loading={lazyLoad ? "lazy" : "eager"}
                        />
                        <div>
                          <div>
                            {searchResult.averageRating}{" "}
                            <span>({searchResult.totalReviews})</span>
                          </div>
                        </div>
                      </div>
                    )}
                  {searchResult.duration && (
                    <div
                      className="search-result-rating"
                      style={{ marginLeft: 10 }}
                    >
                      <img
                        src={ClockIcon}
                        loading={lazyLoad ? "lazy" : "eager"}
                      />{" "}
                      <span>{formatTimeDuration(searchResult.duration)}</span>
                    </div>
                  )}
                  {searchResult.durationFrom && searchResult.durationTo && (
                    <div className="search-result-rating">
                      <img
                        src={ClockIcon}
                        loading={lazyLoad ? "lazy" : "eager"}
                      />{" "}
                      <span>
                        {formatTimeDuration(searchResult.durationFrom)} -{" "}
                        {formatTimeDuration(searchResult.durationTo)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="search-result-price-from">
                  {searchResult.source}
                </div>
              </div>
            </div>
            <div>
              <div className="search-result-price-from">From</div>
              <div className="search-result-price">
                {formatCurrency.format(searchResult.price)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
