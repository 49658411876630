import * as React from "react";
import clsx from "clsx";

import "./CityLink.css";

export default function CityLink({
  id,
  cityLink,
  description,
  large,
  small,
  image,
}) {
  return (
    <a
      key={id}
      href={cityLink.slug}
      className={clsx("city-link", { ["small"]: small, ["large"]: large })}
    >
      {image}
      <div className="label">{cityLink.city}</div>
    </a>
  );
}
